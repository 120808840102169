import React from 'react';

export class Well extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      justClicked: null,
      color: "transparent",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    console.log(this.props.label+this.state.color);
    if (this.state.color != "transparent") {
      this.setState({ color: "transparent" });
    } else {
      this.setState({ color: document.getElementById("selectedColor").value });
    }
  }
  render() {
    return (
        <>
      <button className="well" key={this.props.row * this.props.col} onClick={() => this.handleClick()} style={{backgroundColor: this.state.color}}>
        {this.props.label}
      </button>
      </>
    );
  }
}

export default Well;
