import React from 'react';
import './plate.css';
import wellData from './wells.json';
import Well from './Well';

function addWells(row) {
  const wellList = wellData
    .filter((item) => item.row === row)
    .forEach((wellItem) => {
      if (wellItem !== undefined) {
        <Well props={wellItem} />;
      }
    });

  return wellList;
}

class Plate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      justClicked: null,
      rows: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
      cols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    };
    this.handleClick = this.handleClick.bind(this);
    this.setPlate = this.setPlate.bind(this);
  }

  handleClick() {
    console.log(this.state);
    this.setState({ justClicked: !this.state.justClicked });
  }

  setPlate() {
    let selectElement = document.getElementsByClassName('selectPlate')[0];
    let wellNum = selectElement.options[selectElement.selectedIndex].value;
    let rows = null;
    let cols = null;
    if (wellNum == "96") {
      rows = ["A", "B", "C", "D", "E", "F", "G", "H"];
      cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    } else if (wellNum == "24") {
      rows = ["A", "B", "C", "D"];
      cols = [1, 2, 3, 4, 5, 6];
    } else if (wellNum == "12") {
      rows = ["A", "B", "C"];
      cols = [1, 2, 3, 4];
    } else if (wellNum == "6") {
      rows = ["A", "B"];
      cols = [1, 2, 3];
    }

    this.setState({ rows: rows });
    this.setState({ cols: cols });
}

  render() {
    return (
      <div>
        <select className='selectPlate' onChange={this.setPlate} disabled>
          <option value="96">96 Wells</option>
          <option value="24">24</option>
          <option value="12">12</option>
          <option value="6">6</option>
        </select>
        <input id="selectedColor" type="color" className='colorSelector'></input>
        <div>
        {this.state.rows.map((row) => (
          <div className="grid" id={'row' + row}>
            {this.state.cols.map((col) => (
                <Well label={row+col} row={row} col={col} handleClick={this.handleClick} />
            ))}
          </div>
        ))}</div>
      </ div>
    );
  }
}

export default Plate;
