import './App.css';
import NavigationPane from './Header';
import Plate from './96Plate';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import useWindowDimensions from './windowComponent';

export const isLoggedIn = true;

// export function Header() {
//   return (
//     <h1 className="Page-Name">Plate Assistant</h1>
//   );
// }

// let [rows, wells] = Plate();

// export function DisplayPlate() {
//   let plate = <div id="nintysix-well" style={{display: 'table'}}></div>
//     for (let i = 0; i < rows.length; i++) {
//       let row = <div className={rows[i]} style={{display: 'table-row'}}></div>
//       for (let j = 0; j < wells.length; j++) {
//         row.addchild
//       }
//       plate = plate.concat(row)
//       console.log("Test", i)
//     };

//   return (plate);
// }

export function AdminPanel() {
  return <h1>Admin</h1>;
}

export function LoginForm() {
  return <h1>Login</h1>;
}

function App() {
  return (
    <>
      <div className="main-div" id="page" style={{flex: 1}}>
        <NavigationPane />
        <div className='spacer' style={{flex: 1, justifyContent: 'space-between'}}></div>
        <div style={{flex: 1, justifyContent: 'flex-end'}}>
          <Plate id="plate"/>
        </div>
        <div>{console.log(useWindowDimensions())}</div>
      </div>
    </>
  );
}

export default App;
