import React, { PureComponent } from 'react';
import './Header.css';
import Popup from 'reactjs-popup';

export function RegisterPane() {
  return (
    <div>
      <h1>Create and account</h1>;
      <h2>Enter your email to register for this app</h2>;
      <h2>
        Already registed? <a href="/login">Login here</a>
      </h2>
      ;
    </div>
  );
}

export default function NavigationPane() {
  return (
    <div className="nav-div">
      <div style={{ display: 'table', width: '100%' }} align="right">
        <div
          className="nav-title"
          style={{ display: 'table-row' }}
          align="left"
        >
          <div className="page-title" align="left">
            Plate Assistant
          </div>
        </div>
        <div
          className="nav-btns"
          style={{ display: 'table-row' }}
          align="right"
        >
          <button className="nav-sub-btn">Settings</button>
          <button className="nav-sub-btn">About</button>
          <button className="nav-sub-btn">Contact</button>
          <Popup
            trigger={<button className="nav-register-btn">Register</button>}
            position={'bottom right'}
          >
            <RegisterPane />
          </Popup>
        </div>
      </div>
    </div>
  );
}
